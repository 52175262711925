import React, { ReactElement } from 'react'
import { Box, Tab, Tabs, styled } from '@mui/material'

export interface StyledTabProps {
  label: string | ReactElement
  disabled?: boolean
}

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  isOtherPage?: boolean
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, isOtherPage, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: isOtherPage ? 0 : 3, pl: isOtherPage ? 0.5 : 0 }}>{children}</Box>
      )}
    </Box>
  )
}

export const StyledOutlineTabs = styled(Tabs)({
  minWidth: '320px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 4,
  },
})

export const StyledOutlineTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    padding: 8,
    borderRadius: 2,
    border: '1px solid #D9D9D9',
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: 16,
    fontWeight: 325,
    backgroundColor: '#FAFAFA',
    color: '#262626',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-selected': {
      color: '#2b388d',
      backgroundColor: 'white',
      fontWeight: 350,
      borderBottom: 'none',
    },
    '&.Mui-focusVisible': {
      color: 'black',
      backgroundColor: '#dee5ef',
    },
  })
)

export const StyledContainedTabs = styled(Tabs)({
  minWidth: '320px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 4,
  },
})

export const StyledContainedTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  padding: 15,
  borderRadius: 2,
  border: '1px solid #dee5ef',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontSize: 14,
  fontWeight: 350,
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: 'white',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#2b388d',
    fontWeight: 350,
    borderBottom: 'none',
  },
  '&.Mui-focusVisible': {
    color: 'black',
    backgroundColor: '#dee5ef',
  },
}))

export const StyledDefaultTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: 16,
    fontWeight: 325,
    color: '#262626',
    '&.Mui-selected': {
      color: '#2b388d',
      fontWeight: 350,
    },
    '&.Mui-focusVisible': {
      color: 'black',
    },
  })
)
