import React from 'react'
import { Chip } from '@mui/material'
import { EWorkflowState } from '../service/enum'

interface Props {
  status: EWorkflowState | undefined
  isTableDisplay?: boolean
}

export const statusMap = {
  pre_bill: {
    label: 'Pre-bill',
    color: '#1271AF',
    bgcolor: 'rgba(2, 136, 209, 0.12)',
    border: '',
  },
  client_approval: {
    label: 'Needs Client Approval',
    color: '#089171',
    bgcolor: '#CFFCF2',
    border: '1px solid #089171',
  },
  verbal_approval: {
    label: 'Needs Verbal Approval',
    color: '#2B388D',
    bgcolor: 'rgba(43, 56, 141, 0.08)',
    border: '1px solid #2B388D',
  },
  poa_approval: {
    label: 'Needs POA',
    color: '#1271AF',
    bgcolor: 'rgba(2, 136, 209, 0.12)',
    border: '1px solid #03A9F4',
  },
  org_approval: {
    label: 'Needs Plumb Approval',
    color: '#8E4101',
    bgcolor: 'rgba(215, 126, 40, 0.08)',
    border: '1px solid #FF9800',
  },
  changes_required: {
    label: 'Changes Required',
    color: '#7F1C1C',
    bgcolor: 'rgba(211, 47, 47, 0.12)',
    border: '1px solid #EF5350',
  },
  payment_pending: {
    label: 'Payment Pending',
    color: 'warning.main',
    bgcolor: 'rgba(215, 126, 40, 0.08)',
    border: '',
  },
  paid: {
    label: 'Paid',
    color: '#089171',
    bgcolor: '#CFFCF2',
    border: '',
  },
  rejected: {
    label: 'Rejected',
    color: 'error.main',
    bgcolor: 'rgba(255, 0, 0, 0.12)',
    border: '',
  },
}

const BillWorkflowStatusChip: React.FC<Props> = ({ status, isTableDisplay }) => {
  if (!status) return <Chip />

  const { label, color, bgcolor, border } = statusMap[status]
  return <Chip label={label} sx={{ color, bgcolor, border: isTableDisplay ? border : undefined }} />
}

export default BillWorkflowStatusChip
