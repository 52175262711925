import React from 'react'
import { Chip } from '@mui/material'

interface Props {
  isFullyPaid: boolean
  isTableDisplay?: boolean
}

const BillPaymentStatusChip: React.FC<Props> = ({ isFullyPaid, isTableDisplay }) => {
  let label, color, bgcolor, border

  if (isFullyPaid) {
    label = 'Paid in Full'
    color = '#4CAF50'
    bgcolor = 'rgba(46, 125, 50, 0.12)'
    border = '1px solid #4CAF50'
  } else {
    label = 'Partial Payment'
    color = '#ED6C02'
    bgcolor = 'rgba(237, 108, 2, 0.3)'
    border = '1px solid #ED6C02'
  }

  return <Chip label={label} sx={{ color, bgcolor, border: isTableDisplay ? border : undefined }} />
}

export default BillPaymentStatusChip
